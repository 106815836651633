import NotificationToast from "../lib/NotificationToast";
import {CustomConfirm, CustomConfirmLogout} from "../lib/CustomConfirm";
import autoComplete from "@tarekraafat/autocomplete.js";
import formControl from "../lib/FormControl";
import {SelectInput, SelectInputTop} from "../Components/SelectInput";
import {DatePicker, DatePickerTwo, DateTimePicker} from "../Components/DatePicker";
import Chart from 'chart.js/auto';
import autocolors from 'chartjs-plugin-autocolors';

let Turbolinks = require("turbolinks")
Turbolinks.start()

//Custom Select Input
customElements.define("custom-select", SelectInput, {extends: "select"})
customElements.define("custom-select-top", SelectInputTop, {extends: "select"})
customElements.define("date-picker", DatePicker, {extends: "input"})
customElements.define("date-time-picker", DateTimePicker, {extends: "input"})
customElements.define("date-picker-two", DatePickerTwo, {extends: "input"})

function getAllFormSubit() {
    let allForm = document.querySelectorAll(".modal__body form")
    allForm.forEach((item) => {
        item.addEventListener("submit", (e) => {
            e.preventDefault()
            document.querySelector(".modal__box.active .modal__footer .form__submit__btn").click()
        })
    })
}

// Navigation Dropdown item
document.addEventListener("turbolinks:load", function () {

    // Autoclose dropdown
    window.addEventListener("click", (e) => {
        if (!e.target.matches('.icon.bx.bx-dots-vertical-rounded') && !e.target.matches('.table_drop_action')) {
            document.querySelectorAll('.table_drop_action').forEach(elm => {
                if (elm.classList.contains("active")) {
                    elm.classList.remove("active");
                }
            });
        }

        if (document.querySelector(".show_magasin .default_option") !== null) {
            if (!e.target.matches('.show_magasin .default_option')) {
                if (document.querySelector(".show_magasin .default_option + ul").classList.contains("active")) {
                    document.querySelector(".show_magasin .default_option + ul").classList.remove("active");
                }
            }
        }

        if (document.querySelector(".dropdownList .default_option") !== null) {
            if (!e.target.matches('.dropdownList .default_option')) {
                if (document.querySelector(".dropdownList .default_option + ul").classList.contains("active")) {
                    document.querySelector(".dropdownList .default_option + ul").classList.remove("active");
                }
            }
        }

        if (document.querySelector(".dropdown .default_option") !== null) {
            if (!e.target.matches('.dropdown .default_option')) {
                if (document.querySelector(".dropdown .default_option + ul").classList.contains("active")) {
                    document.querySelector(".dropdown .default_option + ul").classList.remove("active");
                }
            }
        }

    });

    // Filter Drop
    let filterDrop = document.querySelector(".dropdown .default_option");
    if (filterDrop !== null && filterDrop !== undefined) {
        filterDrop.addEventListener("click", () => {
            let filterElm = document.querySelector(".dropdown .default_option + ul");
            filterElm.classList.toggle("active");
        });
    }

    let show_magasin = document.querySelector(".show_magasin .default_option");
    let show_magasinElm = document.querySelector(".show_magasin .default_option + ul");
    if (show_magasin !== null && show_magasin !== undefined) {
        show_magasin.addEventListener("click", () => {
            show_magasinElm.classList.toggle("active");
        });
    }

    let dropdownList = document.querySelector(".dropdownList .default_option");
    let dropdownListElm = document.querySelector(".dropdownList .default_option + ul");
    if (dropdownList !== null && dropdownList !== undefined) {
        dropdownList.addEventListener("click", () => {
            dropdownListElm.classList.toggle("active");
        });
    }

    // Gestion de la suppression
    let formDelete = document.querySelectorAll(".deleteForm");
    formDelete.forEach((item) => {
        item.addEventListener("submit", (e) => {
            e.preventDefault();
            CustomConfirm(item.dataset.content, item)
        })
    })

    let link_delete = document.querySelectorAll(".link_delete");
    link_delete.forEach((item) => {
        item.addEventListener("click", (e) => {
            e.preventDefault();
            let url = item.dataset.url
            let text = item.dataset.content
            if (url === '' || url === null) {
                return 0
            }
            item.action = url
            CustomConfirm(text, item)
        })
    })

    // Table DropDown Action menu
    let table_drop_action = document.querySelectorAll('.table_drop_action');
    table_drop_action.forEach(element => {
        element.addEventListener("click", () => {
            table_drop_action.forEach(elm => {
                if (elm.classList.contains("active") && elm !== element) {
                    elm.classList.remove("active");
                }
            });
            element.classList.toggle("active");
        });
    });

    let body = document.querySelector('body'),
        sidebar = body.querySelector('nav'),
        toggle = body.querySelector(".toggle"),
        modeSwitch = body.querySelector(".toggle-switch"),
        modeText = body.querySelector(".mode-text");

    // Load Theme et sideBar from localStorage
    if (sidebar !== null) {
        loadTheme()
    }

    function loadTheme() {
        let sidebarClose = localStorage.getItem("sidebarClose")
        let modeThemeDark = localStorage.getItem("modeThemeDark")
        let view_data = localStorage.getItem("view_data")

        if (sidebarClose !== null && sidebarClose !== undefined && sidebarClose === 'false') {
            sidebar.style.transition = "none"
            sidebar.querySelector("header .toggle").style.transition = "none"
            body.querySelector(".home").style.transition = "none"
            sidebar.classList.remove("close");
        }

        if (modeThemeDark !== null && modeThemeDark !== undefined && modeThemeDark === 'true') {
            body.style.transition = "none"
            body.classList.add("dark");
        }

        if (view_data !== null && view_data !== undefined) {
            let btn = document.querySelector("button[data-show='" + view_data + "']")
            document.querySelector(".active[data-show]")?.classList.remove("active")
            document.querySelector(".active[data-content-show]")?.classList.remove("active")
            document.querySelector("[data-content-show='" + view_data + "']")?.classList.add("active")
            btn?.classList.add("active")
        }
    }

// Toggle sideBar
    toggle?.addEventListener("click", () => {
        sidebar.classList.toggle("close");
        localStorage.setItem("sidebarClose", sidebar.classList.contains("close").toString())
    })

    modeSwitch?.addEventListener("click", () => {
        body.classList.toggle("dark");

        if (body.classList.contains("dark")) {
            modeText.innerText = "Light mode";
        } else {
            modeText.innerText = "Dark mode";
        }

        localStorage.setItem("modeThemeDark", body.classList.contains("dark").toString())
    });


    // call_url_APIEndPoint
    let call_url_APIEndPoint = document.querySelectorAll(".call_url_APIEndPoint")
    call_url_APIEndPoint.forEach((item) => {
        item.addEventListener('click', () => {
            let url = item.dataset.url
            if (url !== null && url !== undefined) {
                fetch(url, {
                    method: "POST",
                    body: null,
                })
                    .then(res => res.json())
                    .then(data => {
                        if (data.type === "success") {
                            let generateEditForm = document.querySelector("#generate__form")
                            generateEditForm.innerHTML = data.message

                            let requiredText = generateEditForm.querySelector("[data-control ~= 'noEmpty']")
                            if (requiredText !== null){
                                if(generateEditForm.querySelector(".required__placeholder") == null){

                                    let paragraph = document.createElement('p')
                                    paragraph.classList.add('required__placeholder')
                                    paragraph.innerHTML = `<i class="fi-rr-exclamation"></i> champs obligatoires`

                                    generateEditForm.querySelector(".modal__container").insertBefore(paragraph,generateEditForm.querySelector(".modal__footer"))
                                }
                            }

                            setTimeout(function () {
                                generateEditForm.querySelector('.modal__box').classList.add('active')
                            }, 100)

                            let form = generateEditForm.querySelector("form")
                            if (form !== null){
                                form.method = "post"
                            }

                            let close__modal = generateEditForm.querySelectorAll(".close__modal")
                            if (close__modal !== null && close__modal !== undefined) {
                                close__modal.forEach((item) => {
                                    item.addEventListener("click", () => {
                                        CloseModal()
                                    })
                                })
                            }
                            let modalForm__submit = generateEditForm.querySelector(".modalForm__submit")
                            if (modalForm__submit !== null && modalForm__submit !== undefined) {
                                modalForm__submit.addEventListener("click", () => {
                                    submitForm(modalForm__submit)
                                })

                                let form = generateEditForm.querySelector("form")
                                form.addEventListener("submit", (e) => {
                                    e.preventDefault()
                                    submitForm(modalForm__submit)
                                })
                            }

                            loadInputFile()

                            let remove_file = generateEditForm.querySelector(".remove_file")
                            if (remove_file !== null && remove_file !== undefined) {
                                remove_file.addEventListener("click", () => {
                                    let reset = generateEditForm.querySelector("input[name='remove_file']")
                                    if (reset !== null && reset !== undefined) {
                                        reset.value = true
                                    }
                                })
                            }
                        } else {
                            NotificationToast(data.type, data.message)
                        }
                    })
                    .catch(err => {
                        NotificationToast("error", "Erreur XHR: " + err)
                    });
            }
        })
    })

    // Custom Search field
    let searchField = document.querySelector(".search_field .input")
    if (searchField !== null && searchField !== undefined) {
        const autoCompleteJS = new autoComplete({
            selector: "#autoComplete",
            placeHolder: "Rechercher...",
            data: {
                src: searchList,
                cache: true,
            },
            resultsList: {
                element: (list, data) => {
                    if (!data.results.length) {
                        // Create "No Results" message element
                        const message = document.createElement("div");
                        // Add class to the created element
                        message.setAttribute("class", "no_result");
                        // Add message text content
                        message.innerHTML = `<span>Aucun résultat trouvé pour "${data.query}"</span>`;
                        // Append message element to the results list
                        list.prepend(message);
                    }
                },
                noResults: true,
                maxResults: 20,
                tabSelect: true,
            },
            // submit form
            submit: true,
            resultItem: {
                highlight: true
            },
            events: {
                input: {
                    selection: (event) => {
                        const selection = event.detail.selection.value;
                        let url = searchField.parentElement.parentElement.parentElement.action
                        const params = new URLSearchParams({
                            q: selection
                        });
                        Turbolinks.visit(url + '?' + params.toString());
                    }
                }
            }
        });
    }

    let view_data = document.querySelectorAll(".view_data button")
    view_data.forEach((item) => {
        item.addEventListener("click", () => {
            let show_slug = item.dataset.show
            if (show_slug !== undefined && show_slug !== null) {
                if (item.classList.contains("active") === false) {
                    item.parentElement.querySelector(".active")?.classList.remove("active")
                    item.classList.add("active")
                    document.querySelector(".active[data-content-show]")?.classList.remove("active")
                    document.querySelector("[data-content-show='" + show_slug + "']").classList.add("active")
                    localStorage.setItem('view_data', show_slug)
                }
            }
        })
    })

    let custom__link__visit = document.querySelectorAll(".custom__link__visit")
    custom__link__visit.forEach((item) => {
        item.addEventListener("click", () => {
            let url = item.dataset.url
            if (url !== null) {
                Turbolinks.visit(url)
            }
        })
    })

    let js_add_transfert = document.querySelector(".js_add_transfert")
    if (js_add_transfert !== null) {
        js_add_transfert.addEventListener("click", () => {
            js_add_transfert.setAttribute("disabled", "")
            let form = js_add_transfert.parentElement.querySelector("form")
            let url = form.action
            if (url !== null) {
                let payload = new FormData(form)
                let formControlResult = formControl(form)
                if (formControlResult === true) {
                    fetch(url, {
                        method: "POST",
                        body: payload,
                    })
                        .then(res => res.json())
                        .then(data => {
                            Turbolinks.visit(data?.redirect)
                            document.addEventListener("turbolinks:load", function () {
                                if (data !== undefined) {
                                    NotificationToast(data.type, data.message)
                                    setTimeout(() => { //remove data after 500ms
                                        data = undefined;
                                    }, 500);
                                }
                            })
                        })
                        .catch(err => {
                            js_add_transfert.removeAttribute("disabled")
                            NotificationToast("error", "Erreur XHR: " + err)
                        });
                    return true
                }
                setTimeout(() => {
                    js_add_transfert.removeAttribute("disabled")
                }, 1000)
            }
        })
    }

    let addTransfert = document.querySelector(".addTransfert")
    if (addTransfert !== null) {
        addTransfert.addEventListener('click', () => {
            addTransfert.setAttribute("disabled", "")
            let form = document.querySelector("#addTransfertData")
            let url = form.action
            if (url !== null) {
                let payload = new FormData(form)
                document.querySelector('.spinner__loader').classList.add('active')
                fetch(url, {
                    method: "POST",
                    body: payload,
                })
                    .then(res => res.json())
                    .then(data => {
                        document.querySelector('.spinner__loader').classList.remove('active')
                        Turbolinks.visit(data?.redirect)
                        document.addEventListener("turbolinks:load", function () {
                            if (data !== undefined) {
                                NotificationToast(data.type, data.message)
                                setTimeout(() => { //remove data after 500ms
                                    data = undefined;
                                }, 500);
                            }
                        })
                    })
                    .catch(err => {
                        addTransfert.removeAttribute("disabled")
                        document.querySelector('.spinner__loader').classList.remove('active')
                        NotificationToast("error", "Erreur XHR: " + err)
                    });
                setTimeout(() => {
                    addTransfert.removeAttribute("disabled")
                }, 1000)
                return true
            }
        })
    }

    let magasin_id_source = document.querySelector("#magasin_id_source")
    magasin_id_source?.addEventListener("change", () => {
        if (magasin_id_source?.selectedIndex !== "" || magasin_id_source.selectedIndex > 0) {
            getAllArticleFromStock(magasin_id_source)
            let otherMagasin = document.querySelector(".target__allOthersMagasin")
            if (otherMagasin !== null) {
                getAllOtherMagasin(magasin_id_source)
            }
            let qte_sortie_destination = document.querySelector("#qte_sortie_destination")
            if (qte_sortie_destination !== null) {
                qte_sortie_destination.value = ""
            }
        }
    })

    let qte_sortie_destination = document.querySelector("#qte_sortie_destination")
    qte_sortie_destination?.addEventListener("input", () => {
        let value = qte_sortie_destination.value
        if (!isNaN(parseInt(qte_sortie_destination.max, 10))) {
            if (value > parseInt(qte_sortie_destination.max, 10)) {
                qte_sortie_destination.value = qte_sortie_destination.max
            } else if (value < 0) {
                qte_sortie_destination.value = 0
            }
        }
    })

    let toggle__table_btn = document.querySelectorAll(".toggle__table_btn")
    toggle__table_btn.forEach((item) => {
        item.addEventListener('click', () => {
            let parent = item.parentElement.parentElement.parentElement
            parent.classList.toggle("active")
            parent.nextElementSibling.classList.toggle("show")
        })
    })

    // Gestion de l'exportation vers excel
    let excel_export = document.querySelectorAll(".excel__export");
    excel_export.forEach((item) => {
        item.addEventListener("click", (e) => {
            e.preventDefault();
            let exportAnimation = document.querySelector(".excel_export_popUp")
            exportAnimation.classList.add("active")

            let url = item.href;
            let docName = (item.dataset.name).trim()

            fetch(url).then(res => res.blob()).then(file => {
                let tempUrl = URL.createObjectURL(file);
                const aTag = document.createElement("a");
                aTag.href = tempUrl;
                let date = new Date();
                aTag.download = docName + '.xlsx';
                document.body.appendChild(aTag);
                aTag.click();
                URL.revokeObjectURL(tempUrl);
                aTag.remove();
                exportAnimation.classList.remove("active");
                NotificationToast("success", "Exportation vers <b>Excel</b> reussi");
            }).catch(() => {
                exportAnimation.classList.remove("active");
                NotificationToast("error", "Erreur lors de l'exportation...");
            });

        })
    })

    let form__login = document.querySelector("#form__login")
    form__login?.addEventListener("submit", (e) => {
        e.preventDefault()
        let formElement = form__login
        let payload = new FormData(formElement)
        let url = formElement.action
        let formControlResult = formControl(formElement)
        if (formControlResult === true) {
            fetch(url, {
                method: "POST",
                body: payload,
            })
                .then(res => res.json())
                .then(data => {
                    // showModal()
                    Turbolinks.visit(data?.redirect + window.location.search)
                    document.addEventListener("turbolinks:load", function () {
                        if (data !== undefined) {
                            NotificationToast(data.type, data.message)
                            setTimeout(() => { //remove data after 500ms
                                data = undefined;
                            }, 500);
                        }

                    })
                })
                .catch(err => {
                    NotificationToast("error", "Erreur XHR: " + err)
                });
            return true
        }
    })

    let JSlogout__user = document.querySelector("#JSlogout__user")
    JSlogout__user?.addEventListener("click", (e) => {
        e.preventDefault()
        CustomConfirmLogout(JSlogout__user.href)
    })

    // Chart graphique
    const chart__categorieArticle = document.querySelector("#chart__categorieArticle")
    if (chart__categorieArticle !== null) {
        const data = articleByCategorie

        new Chart(
            chart__categorieArticle,
            {
                type: 'doughnut',
                data: {
                    labels: data.map(row => row.categorie),
                    datasets: [
                        {
                            label: 'Articles',
                            data: data.map(row => row.count)
                        }
                    ]
                },
                options: {
                    plugins: {
                        autocolors,
                        legend: {
                            labels: {
                                // This more specific font property overrides the global property
                                font: {
                                    family: 'poppins'
                                }
                            }
                        },
                        tooltip: {
                            bodyFont: {
                                family: 'poppins',
                                weight: '400'
                            },
                            titleFont: {
                                family: 'poppins',
                                weight: '400'
                            }
                        },
                        subtitle: {
                            display: true,
                            text: 'Articles/Catégories',
                            font: {
                                family: 'poppins',
                                weight: '700'
                            }
                        }
                    }
                }
            }
        );
    }

    const chart__stockArticle = document.querySelector("#chart__stockArticle")
    if (chart__stockArticle !== null) {
        const data = articleByStock

        new Chart(
            chart__stockArticle,
            {
                type: 'doughnut',
                data: {
                    labels: data.map(row => row.magasin),
                    datasets: [
                        {
                            label: 'Articles',
                            data: data.map(row => row.count)
                        }
                    ]
                },
                options: {
                    plugins: {
                        autocolors,
                        legend: {
                            labels: {
                                // This more specific font property overrides the global property
                                font: {
                                    family: 'poppins'
                                }
                            }
                        },
                        tooltip: {
                            bodyFont: {
                                family: 'poppins',
                                weight: '400'
                            },
                            titleFont: {
                                family: 'poppins',
                                weight: '400'
                            }
                        },
                        subtitle: {
                            display: true,
                            text: 'Articles/Magasins',
                            font: {
                                family: 'poppins',
                                weight: '700'
                            }
                        }
                    }
                }
            }
        );
    }

    const chart__statsMoov = document.querySelector("#chart__statsMoov")
    if (chart__statsMoov !== null) {
        const data = dataStats
        let datasets = [];
        Object.keys(data).forEach(function (key, index) {
            datasets.push(
                {
                    label: key,
                    data: Object.values(data[key]),
                    pointStyle: 'circle',
                    pointRadius: 5,
                    pointHoverRadius: 8,
                    tension: 0.2
                }
            )
        });

        new Chart(
            chart__statsMoov,
            {
                type: 'line',
                data: {
                    labels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
                    datasets: datasets
                },
                options: {
                    plugins: {
                        autocolors,
                        legend: {
                            labels: {
                                // This more specific font property overrides the global property
                                font: {
                                    family: 'poppins',
                                    weight: '500'
                                }
                            }
                        },
                        tooltip: {
                            bodyFont: {
                                family: 'poppins',
                                weight: '400'
                            },
                            titleFont: {
                                family: 'poppins',
                                weight: '400'
                            }
                        },
                        subtitle: {
                            display: true,
                            text: 'Situation Inventaires/Transferts/Livraisons',
                            font: {
                                family: 'poppins',
                                weight: '700'
                            }
                        }
                    },
                    scales: {
                        y: {
                            ticks: {
                                font: {
                                    family: 'poppins',
                                    weight: '500'
                                }
                            }
                        },
                        x: {
                            ticks: {
                                font: {
                                    family: 'poppins',
                                    weight: '500'
                                }
                            }
                        }
                    }
                }
            }
        );
    }
})


function getAllQteFromStock(article, magasin, magasin2) {
    if (!isNaN(article)) {
        let form = document.querySelector("#form__transfert_livraison")
        let uri = form.dataset.stock.substr(0, (form.dataset.stock.length - 1)) + article + "/" + magasin
        if (magasin2 !== undefined) {
            uri = form.dataset.stock.substr(0, (form.dataset.stock.length - 2)) + article + "/" + magasin + "/" + magasin2
        }

        fetch(uri, {
            method: "POST",
            body: null,
        })
            .then(res => res.json())
            .then(data => {
                let qte_sortie_destination = document.querySelector("#qte_sortie_destination")
                qte_sortie_destination.value = data.stock
                qte_sortie_destination.setAttribute("max", data.stock)
                qte_sortie_destination.setAttribute("min", '0')
            })
            .catch(err => {
                NotificationToast("error", "Erreur XHR: " + err)
            });

        return true
    }
}

function getAllArticleFromStock(element) {
    let value = parseInt(element.value, 10)
    if (!isNaN(value)) {
        let form = document.querySelector("#form__transfert_livraison")
        fetch(form.dataset.articles + value, {
            method: "POST",
            body: null,
        })
            .then(res => res.json())
            .then(data => {
                if (data.type === "success") {
                    document.querySelector(".target__allArticle").innerHTML = data.data
                    document.querySelector(".target__allArticle #article_id")?.addEventListener('change', () => {
                        let element = document.querySelector(".target__allArticle #article_id")
                        let magasin_id_source = document.querySelector("#magasin_id_source")
                        let magasin_id_destination = document.querySelector("#magasin_id_destination")
                        if ((element?.selectedIndex !== "" || element.selectedIndex > 0) && (magasin_id_source?.selectedIndex !== "" || magasin_id_source.selectedIndex > 0)) {
                            getAllQteFromStock(element.value, magasin_id_source.value, magasin_id_destination?.value)
                        }
                    })
                } else {
                    document.querySelector(".target__allArticle").innerHTML = data.data
                }
            })
            .catch(err => {
                NotificationToast("error", "Erreur XHR: " + err)
            });

        return true
    }

}

function getAllOtherMagasin(element) {
    let value = parseInt(element.value, 10);
    if (!isNaN(value)) {
        let form = document.querySelector("#form__transfert_livraison")
        fetch(form.dataset.magasins + value, {
            method: "POST",
            body: null,
        })
            .then(res => res.json())
            .then(data => {
                if (data.type === "success") {
                    document.querySelector(".target__allOthersMagasin").innerHTML = data.data
                } else {
                    document.querySelector(".target__allOthersMagasin").innerHTML = data.data
                }
                document.querySelector("#qte_sortie_destination").value = ""
                document.querySelector("#qte_sortie_destination").removeAttribute("max")
            })
            .catch(err => {
                NotificationToast("error", "Erreur XHR: " + err)
            });

        return true
    }

}

// Functions
function CloseModal() {
    const popUpModal = document.querySelector(".modal__box")
    popUpModal.classList.toggle("active")
    setTimeout(function () {
        document.querySelector("#generate__form").innerHTML = ""
    }, 200)
}

function submitForm(element) {
    element.setAttribute("disabled", "")
    let animation = element.parentElement.parentElement
    let formParent = element.parentElement.parentElement.querySelector(".modal__body")
    let formElement = formParent.querySelector(".modal__body form")
    let payload = new FormData(formElement)
    let url = formElement.action
    let formControlResult = formControl(formElement)
    if (formControlResult === true) {
        animation.classList.add("show__loader")
        fetch(url, {
            method: "POST",
            body: payload,
        })
            .then(res => res.json())
            .then(data => {
                animation.classList.add("show__loader")
                Turbolinks.visit(data?.redirect + window.location.search)
                document.addEventListener("turbolinks:load", function () {
                    if (data !== undefined) {
                        NotificationToast(data.type, data.message)
                        setTimeout(() => { //remove data after 500ms
                            data = undefined;
                        }, 500);
                    }
                })
            })
            .catch(err => {
                element.removeAttribute("disabled")
                animation.classList.remove("show__loader")
                NotificationToast("error", "Erreur XHR: " + err)
            });
        return true
    }
    setTimeout(() => {
        element.removeAttribute("disabled")
    }, 1000)
}

function loadInputFile(source = null) {

    if (source === null) {
        source = ".modal__box "
    }

    let inputUploadImage = document.querySelector(source + "#upload-image");
    let uploadImagePreview = document.querySelector(source + "#upload-image-preview");
    let uploadImageDeletePreview = document.querySelector(source + "#deleteUploadImage");

    if (inputUploadImage !== null && inputUploadImage !== undefined) {
        inputUploadImage.addEventListener("change", () => {
            if (inputUploadImage.files && inputUploadImage.files.length === 1 && inputUploadImage.files[0].size > 2097152) {
                alert("La taille de l'image ne doit pas dépasser " + parseInt(2097152 / 1024 / 1024) + " MB");
                inputUploadImage.value = null;
                uploadImagePreview.setAttribute('src', "/vendors/images/logo-placeholder.jpg");
                uploadImageDeletePreview.style.display = "none";
            } else {
                const fileSrc = inputUploadImage.files[0];
                if (fileSrc) {
                    const reader = new FileReader();
                    reader.addEventListener("load", () => {
                        if (inputUploadImage.files[0].type.indexOf("image") !== -1) uploadImagePreview.setAttribute('src', reader.result);
                        uploadImageDeletePreview.style.display = "block";
                    });
                    reader.readAsDataURL(fileSrc);
                } else {
                    // inputUploadImage.value = uploadImagePreview.src;
                    if (inputUploadImage.files[0].type.indexOf("image") !== -1) uploadImagePreview.setAttribute('src', "/vendors/images/dev_img/logo-placeholder.jpg");
                    uploadImageDeletePreview.style.display = "none";
                }
            }
        });

        uploadImageDeletePreview.addEventListener("click", (e) => {
            e.preventDefault();
            if (inputUploadImage.value != null) {
                inputUploadImage.value = null;
                uploadImagePreview.setAttribute('src', "/vendors/images/logo-placeholder.jpg");
                uploadImageDeletePreview.style.display = "none";
            }
        });
    }
}